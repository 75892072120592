import React, { FC } from "react";
import { LinkProps } from "../../types";

export const Link: FC<LinkProps> = ({ href, value, prefix }) =>
  href && value ? (
    <p>
      {prefix}
      <a href={href} target="_blank" rel="noreferrer">
        {value}
      </a>
    </p>
  ) : null;

