import { IComune } from "../../types";
import { Text } from "../atoms/Text";
import { Link } from "../atoms/Link";
import { MAP_ENDPOINT } from "../../utils/constants";

export const Comune = ({ comune }: { comune: IComune }) => {
  const {
    stemma,
    comune: nome,
    pop_res_21,
    pro_com_t,
    cap,
    cf,
    den_prov,
    sigla,
    den_reg,
    cod_reg,
    lat,
    long,
    mail,
    pec,
    sito_web,
    wikipedia,
  } = comune;

  return (
    <div className="comune">
      <img className="stemma" src={stemma} alt={nome} />
      <Text value={nome} type="heading" prefix="Comune di " />
      <Text value={pop_res_21} prefix="Popolazione: " suffix=" abitanti" />
      <Text value={pro_com_t} prefix="Codice ISTAT: " />
      <Text value={cap} prefix="CAP: " />
      <Text value={cf} prefix="CF: " />
      <Text value={`${den_prov} (${sigla})`} prefix="Provincia: " />
      <Text value={den_reg} prefix="Regione: " />
      <Text value={cod_reg} prefix="Codice Regione: " />
      <Link
        href={`${MAP_ENDPOINT}/${lat}/${long}`}
        value={`${lat}, ${long}`}
        prefix="Coordinate: "
      />
      <Link href={`mailto:${mail}`} value={mail} prefix="Email: " />
      <Link href={`mailto:${pec}`} value={pec} prefix="PEC: " />
      <Link href={sito_web} value={sito_web} prefix="Sito Web: " />
      <Link href={wikipedia} value={wikipedia} prefix="Wikipedia: " />
    </div>
  );
};

