import { useState } from "react";
import { IComune, SelectOption } from "../types";
import { AxiosResponse } from "axios";

interface IUseSearch {
  data?: AxiosResponse<any> | undefined;
}

export const useSearch = ({ data }: IUseSearch) => {
  const [comune, setComune] = useState<IComune | null>(null);
  const [results, setResults] = useState<SelectOption[]>([]);
  const [showResults, setShowResults] = useState<boolean>(true);
  const [selected, setSelected] = useState<string | null>(null);

  const getComune = (pro_com_t: string) => {
    const selectedComune = data?.data.find(
      (comune: IComune) => comune.pro_com_t === pro_com_t
    );
    setComune(selectedComune || null);
  };

  const handleOnSelect = (result: SelectOption | null) => {
    if (result) {
      getComune(result.value);
    }
  };

  const handleOnSearch = (string: string, results: SelectOption[]) => {
    if (string === "") {
      setComune(null);
    }
    setShowResults(true);
    setResults(results);
  };

  const handleOnClear = () => {
    setSelected(null);
    setComune(null);
  };

  const handleOnSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (results.length > 0) {
      setSelected(results[0].name);
      handleOnSelect(results[0]);
      setShowResults(false);
    }
  };

  return {
    comune,
    handleOnSelect,
    handleOnSearch,
    handleOnClear,
    handleOnSubmit,
    results,
    selected,
    setSelected,
    showResults,
    setShowResults,
  };
};

