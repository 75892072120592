import React, { FC } from "react";
import { TextProps } from "../../types";

export const Text: FC<TextProps> = ({
  value,
  type = "paragraph",
  prefix = "",
  suffix = "",
}) => {
  if (!value) return null;

  const content = `${prefix}${value}${suffix}`;

  return type === "heading" ? <h3>{content}</h3> : <p>{content}</p>;
};

