import React, { useState, useEffect } from "react";
import { IComune, SelectOption } from "../types";
import { useAPI } from "../hooks/useAPI";

import { Loader } from "../components/atoms/Loader";
import { SearchBar } from "../components/molecules/SearchBar";
import { useSearch } from "../hooks/useSearch";
import { ComuneData } from "../components/molecules/ComuneData";

const App = () => {
  const [options, setOptions] = useState<SelectOption[]>([]);
  const { data, isLoading, status } = useAPI();

  const {
    comune,
    handleOnSelect,
    handleOnSearch,
    handleOnClear,
    handleOnSubmit,
    selected,
    showResults,
  } = useSearch({ data });

  useEffect(() => {
    if (status === "success") {
      setOptions(
        data?.data.map((option: IComune, index: number) => ({
          id: index,
          name: `${option.comune} (${option.sigla})`,
          value: option.pro_com_t,
        }))
      );
    }
  }, [data?.data, status]);

  return (
    <div className="container App-header">
      {isLoading ? (
        <Loader color="#0063C6" />
      ) : (
        <>
          <div className="col-12 col-md-4">
            <SearchBar
              options={options}
              handleOnSearch={handleOnSearch}
              handleOnSelect={handleOnSelect}
              handleOnClear={handleOnClear}
              handleOnSubmit={handleOnSubmit}
              selected={selected}
              showResults={showResults}
            />
          </div>
          <div>
            <ComuneData comune={comune} />
          </div>
        </>
      )}
    </div>
  );
};

export default App;

