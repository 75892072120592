import React from "react";
import ReactDOM from "react-dom";
import "./style.css";
import Home from "./pages/Home";

import { QueryClient, QueryClientProvider } from "react-query";
const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Home />
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

