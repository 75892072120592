import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { SelectOption } from "../../types";

interface SearchBarProps {
  options: SelectOption[];
  handleOnSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  handleOnSearch: (string: string, results: SelectOption[]) => void;
  handleOnSelect: (item: SelectOption) => void;
  handleOnClear: () => void;
  selected: string | null;
  showResults: boolean;
}

export const SearchBar = ({
  options,
  handleOnSearch,
  handleOnSelect,
  handleOnClear,
  handleOnSubmit,
  selected,
  showResults,
}: SearchBarProps) => {
  return (
    <form onSubmit={handleOnSubmit}>
      <ReactSearchAutocomplete
        items={options}
        inputSearchString={selected || ""}
        fuseOptions={{ keys: ["name"] }}
        placeholder="Inserisci nome del Comune"
        showNoResultsText="Nessun risultato"
        onSearch={handleOnSearch}
        onSelect={handleOnSelect}
        onClear={handleOnClear}
        autoFocus={selected ? false : true}
        maxResults={showResults ? 10 : 0}
      />
    </form>
  );
};

