import { IComune } from "../../types";
import { Comune } from "../organisms/Comune";
import logo from "../../assets/logo_ods.png";

interface ComuneDataProps {
  comune: IComune | null;
}

export const ComuneData = ({ comune }: ComuneDataProps) => {
  return comune ? (
    <div className="col-12">
      <Comune comune={comune} />
    </div>
  ) : (
    <div className="text-center">
      <img className="stemma" src={logo} alt="OpenDataSicilia" />
    </div>
  );
};

