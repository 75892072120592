import axios from "axios";
import { useQuery } from "react-query";

import { API_ENDPOINT } from "../utils/constants";

export const useAPI = () => {
  const { status, isLoading, data } = useQuery(
    ["comuni"],
    async () => await axios.get(API_ENDPOINT),
    {
      cacheTime: 5000,
      staleTime: 4000,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
  return { status, isLoading, data };
};

