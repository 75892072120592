import React from "react";

interface LoaderProps {
  color?: string;
}

export const Loader = ({ color }: LoaderProps) => (
  <div>
    <h3 style={{ color }}>...</h3>
  </div>
);

